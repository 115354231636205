import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar as MaterialUIAppBar, Typography } from "@material-ui/core/";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export function AppBarView() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MaterialUIAppBar position="static" color="primary">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Andrew Colbeck
          </Typography>
        </Toolbar>
      </MaterialUIAppBar>
    </div>
  );
}
