import { Route } from "./types";

export const gitHub: Route = {
  text: "GitHub",
  icon: "GITHUB",
  alt: "redirect to github.com/AndrewColbeck",
  href: "https://github.com/AndrewColbeck",
  defaultBehaviour: "open new tab",
};

export const linkedIn: Route = {
  text: "LinkedIn",
  icon: "LINKEDIN",
  alt: "redirect to linkedIn.com/AndrewColbeck",
  href: "https://www.linkedin.com/in/andrewcolbeck/",
  defaultBehaviour: "open new tab",
};

export const socialLinks: Route[] = [gitHub, linkedIn];
