import { Button, Grid, Link } from "@material-ui/core";
import React, { useCallback } from "react";
import "./landing.css";
import { socialLinks } from "../../models/external-links";
import { Icons } from "../../icons";
import { siteMap } from "../../models/site-map";
import { Route } from "../../models/types";
import { openInNewTab } from "../../utils";

const landingButtons: Route[] = [...socialLinks, siteMap.contact];

export function LandingView(): JSX.Element {
  const handleClick = useCallback((link: Route) => {
    link.defaultBehaviour === "open new tab" ? openInNewTab(link.href) : console.log("ok");
  }, []);

  return (
    <React.Fragment>
      <div className="LandingView">
        <h1>Welcome.</h1>
        <h4>
          under construction.. please come back soon!{" "}
          <span role="img" aria-label="emoji">
            😁
          </span>
        </h4>
        <p>In the meantime...</p>
        <Grid container className="GridContainer" spacing={2}>
          <Grid item xs={12}>
            {landingButtons.map((link) => (
              <Link key={link.text} onClick={() => handleClick(link)}>
                <Button>{Icons.get[link.icon]}</Button>
              </Link>
            ))}
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}
