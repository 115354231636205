import { ColourTheme } from "./types";

export const blueAndBrown: ColourTheme = {
  background: "#000B18",
  palette: {
    primary: {
      main: "#222a2e",
      light: "#6E8898",
      dark: "#0D1B2A",
      contrastText: "#FFFFFA",
    },
    secondary: {
      light: "#bec2a2",
      main: "#c0b96d",
      dark: "#b18c41",
      contrastText: "#ffffff",
    },
  },
};

export const currentTheme: ColourTheme = blueAndBrown;
