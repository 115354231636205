import React from "react";
import { ThemeProvider } from "@material-ui/core";
import { Theme } from "./theme/theme-provider";
import "./App.css";
import { AppBar, Landing } from "./views";

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <AppBar />
      <Landing />
      {/* <Footer /> */}
    </ThemeProvider>
  );
}

export default App;
