import React from "react";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";

const largeStyle = { width: "11vw", height: "auto" };

export type Available = "GITHUB" | "LINKEDIN" | "EMAIL" | "HOME" | "INFO";
type IconMap = { [key in Available]: JSX.Element };
export const get: IconMap = {
  GITHUB: <GitHubIcon style={largeStyle} />,
  LINKEDIN: <LinkedInIcon style={largeStyle} />,
  EMAIL: <MailOutlineIcon style={largeStyle} />,
  HOME: <HomeIcon style={largeStyle} />,
  INFO: <InfoIcon style={largeStyle} />,
};
