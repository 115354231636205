import { ContactDetails } from "./contact-details";
import { SiteMap } from "./types";

export const siteMap: SiteMap = {
  landing: {
    text: "Home",
    icon: "HOME",
    alt: "Return to home",
    href: "/",
    defaultBehaviour: "append",
  },
  about: {
    text: "About",
    icon: "INFO",
    alt: "go to About me section",
    href: "/about",
    defaultBehaviour: "append",
  },
  contact: {
    text: "Contact",
    icon: "EMAIL",
    alt: "go to Contact details",
    href: `mailto:${ContactDetails.email}`,
    defaultBehaviour: "open new tab",
  },
};
